import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

const Bar = ({ style, startYear, endYear, isSpecial, isTriangleBar, customText, linkUrl }) => {
    const circleSize = '8px';
    const barHeight = isSpecial ? '5px' : '3px';
    const totalYears = 2024 - 2017;
    const paddingPercent = 3;

    const yearToPercent = year => (paddingPercent + ((year - 2017) / totalYears) * (100 - 2 * paddingPercent));
    const widthPercent = yearToPercent(endYear) - yearToPercent(startYear);

    const circleStyle = {
        height: circleSize,
        width: circleSize,
        backgroundColor: style.backgroundColor,
        borderRadius: '50%',
        position: 'absolute',
        top: `calc(50% - ${circleSize} / 2)`,
    };

    const getTriangleStyle = (baseColor, isSmall) => ({
        width: 0,
        height: 0,
        borderTop: isSmall ? '5px solid transparent' : '10px solid transparent',
        borderBottom: isSmall ? '5px solid transparent' : '10px solid transparent',
        borderLeft: isSmall ? `10px solid ${baseColor}` : `15px solid ${baseColor}`,
        position: 'absolute',
        right: '-0.5%',
        top: '50%',
        transform: 'translateY(-50%)',
    });

    const textStyle = {
        position: 'absolute',
        top: '20px',
        left: '50%', // Center the text element horizontally
        transform: 'translateX(-50%)', // Shift the text back by half its width to truly center it
        color: 'black',
        fontSize: '14px',
        fontFamily: "'Raleway', sans-serif",
        fontWeight: '600',
        whiteSpace: 'nowrap',
        overflow: 'visible'
    };

    const adjustedLeft = `calc(${yearToPercent(startYear)}vw)`;

    return (
        <a href={linkUrl} style={{ textDecoration: 'none' }}> {/* Ensure the link does not have default styling */}
            <div style={{ position: 'relative', ...style, left: adjustedLeft, width: `${widthPercent}vw` }}>
                {isSpecial && <div style={{ ...circleStyle, left: 0 }}></div>}
                <div style={{ height: barHeight, width: '100%', backgroundColor: style.backgroundColor }}></div>
                {isSpecial && <div style={getTriangleStyle(style.backgroundColor, false)}></div>}
                {isTriangleBar && <div style={getTriangleStyle(style.backgroundColor, true)}></div>}
                <div style={textStyle}>{customText}</div>
            </div>
        </a>
    );
};

const App = () => {
    const [bars, setBars] = useState([]);

    useEffect(() => {
        const barElements = document.querySelectorAll('.bar');
        const barData = Array.from(barElements).map(bar => ({
            startYear: parseFloat(bar.getAttribute('data-start-year')),
            endYear: parseFloat(bar.getAttribute('data-end-year')),
            color: bar.getAttribute('data-color'),
            verticalShift: bar.getAttribute('data-vertical-shift'),
            isSpecial: bar.classList.contains('special'),
            isTriangleBar: bar.classList.contains('triangleBar'),
            customText: bar.getAttribute('data-custom-text'),
            linkUrl: bar.getAttribute('data-link-url'),
        }));
        setBars(barData);
    }, []);


    return (
        <div>
            {bars.map((bar, index) => (
                <Bar
                    key={index}
                    startYear={bar.startYear}
                    endYear={bar.endYear}
                    isSpecial={bar.isSpecial}
                    isTriangleBar={bar.isTriangleBar}
                    customText={bar.customText}
                    linkUrl={bar.linkUrl} // Pass the URL here
                    style={{
                        backgroundColor: bar.color,
                        position: 'absolute',
                        top: bar.verticalShift
                    }}
                />
            ))}
        </div>
    );
};

ReactDOM.render(<App/>, document.getElementById('root'));
